import Setup from "@/helpers/Setup";
import globalSaveFile from "@/utils/game/GlobalSaveFile";
import Constants from "../../../../configs/constants";
import Scaling from "../../../../configs/scaling";

export default class BaseMapMarker extends Phaser.GameObjects.Container {
  level: number;
  mapID: number;

  constructor(scene: Phaser.Scene, x: number, y: number, type: string, mapID: number, level: number) {
    super(scene, x, y);
    this.scene.add.existing(this);

    /** MARKER: Settings */
    this.setName(type);
    this.level = level;
    this.mapID = mapID;
  }

  startLevel(level: number) {
    this.scene.events.emit('start_level', { level: level })
  }

  createSprite(frame?: string, key = "map_markers") {
    const spriteContainer = this.scene.add.container(0, 0);
    spriteContainer.setName('spriteContainer');

    /** SPRITE */
    const sprite = this.scene.add.sprite(0, 0, key);
    sprite.setName('sprite');

    /** SPRITE: set frame to level */
    if (frame) {
      sprite.setFrame(frame);
    }

    /** SPRITE: Add to container */
    spriteContainer.add(sprite);

    return spriteContainer;
  }

  createStarsObtained(sprite: Phaser.GameObjects.Sprite, level: number) {
    const starsObtained = globalSaveFile.getLevel(this.mapID, level)?.stars || 0;

    const background = this.scene.add.image(0, sprite.height / 2, 'background_map_stars').setOrigin(0.5, 0);
    const container = this.scene.add.container(background.getBounds().left + Scaling.getPixelbyDPR(12), background.getBounds().centerY);
    for (let i = 0; i < 3; i++) {
      const star = this.scene.add.image(0, 0, i <= (starsObtained - 1) ? 'map_marker_star_filled' : 'map_marker_star_empty').setOrigin(0.5);
      star.setPosition((star.getBounds().width / 2) + (i * (star.getBounds().width + Scaling.getPixelbyDPR(4))), 0);

      container.add(star);
    }

    return [background, container];
  }

  createStarsRequired(sprite: Phaser.GameObjects.Sprite, stars: number) {
    const background = this.scene.add.image(0, sprite.height / 2, "background_map_stars").setOrigin(0.5, 0);

    const container = this.scene.add.container(background.getBounds().centerX, background.getBounds().centerY - Scaling.getPixelbyDPR(4));
    const star = this.scene.add.image(0, 0, "map_marker_star_filled").setOrigin(0, 0.5);
    const text = this.scene.add.text(sprite.getBounds().right + Scaling.getPixelbyDPR(4), 0, `x ${stars}`, {
      fontFamily: Constants.FONT_BOLD,
      fontSize: `${Scaling.getPixelbyDPR(16)}px`,
      color: Constants.COLOR_BLACK_HEX
    }).setOrigin(0, 0.5);

    container.add([star, text]);

    container.setX(background.getBounds().centerX - (container.getBounds().width / 2));

    return [background, container]
  }
}