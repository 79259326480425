import Constants from "../../../configs/constants";
import Scaling from "../../../configs/scaling";

export default class Stars extends Phaser.GameObjects.Container {
  textStars: Phaser.GameObjects.Text;

  constructor(scene: Phaser.Scene, x: number, y: number) {
    super(scene, x, y);
    this.scene.add.existing(this);

    const background = this.scene.add.image(0, 0, "background_ui_stars").setOrigin(1, 0.5);
    this.textStars = this.scene.add.text(background.getCenter().x + Scaling.getPixelbyDPR(19), 0, '0/3', {
      fontFamily: Constants.FONT_BOLD,
      fontSize: `${Scaling.getPixelbyDPR(16)}px`,
      color: Constants.COLOR_BLACK_HEX,
      align: 'center'
    }).setOrigin(0.5, 0.5);



    this.add([background, this.textStars]);
  }

  updateText(stars: number, max?: number) {
    this.textStars.setText(`${stars}${max ? `/${max}` : ''}`);
  }
}