import BoardItem from "../containers/board/boardItem";

export default class BoardItems extends Phaser.GameObjects.Group {
  constructor(scene: Phaser.Scene, key: string, destroySpeed: number) {
    super(scene);

    for(let index = 0; index < 120; index++){
      const item = new BoardItem(this.scene, key, destroySpeed);
      item.setActive(false).setVisible(false);
      
      this.add(item);
    }    
  }

  spawn(x: number, y: number, frame: number){
    const sprite = this.getFirstDead() as BoardItem;

    sprite.revive(x, y, frame);

    return sprite;
  }
}