import Phaser from "phaser";
import Scaling from "../configs/scaling";

import Board from "../components/containers/board/board";

import { IBoardSettings } from "../interfaces/board/IBoardSettings";
import { IGameData } from "../interfaces/IGameData";
import { ILevel } from "../interfaces/level/ILevel";
import { ILevelProgression } from "../interfaces/level/ILevelProgression";
import { IDefaultKeyVal } from "../interfaces/core/IDefault";
import { IInputControllerCallback } from "../interfaces/IInputController";
import InputController from "../controllers/input";
import { IMatch3Item } from "../interfaces/board/IMatch3";
import UIGame from "./userinterfaces/game";
import { millisToMinutesAndSeconds } from "../configs/helpers";

import Setup from "@/helpers/Setup";
import sessionSaveFile from "@/utils/game/SessionSaveFile";
import { ScoreDataDefault, ScoreGameTypes } from "@/interfaces/ISessionFile";
import scoreController from "@/utils/game/ScoreController";
import CustomerHelper from "@/helpers/CustomerHelper";
import globalSaveFile from "@/utils/game/GlobalSaveFile";
import Background from "./background";

export default class Main extends Phaser.Scene {
  level?: ILevel | null;
  toMapLevel!: number | undefined;
  levelProgression!: ILevelProgression;
  boardSettings!: IBoardSettings;
  hasSpendLoyalty!: boolean;
  startTime!: Date;
  hasBoughtLoyalty!: boolean;

  constructor() {
    super({ key: "game" });
  }

  init(gameData: IGameData) {
    /** SETTINGS */
    const levelNumber = gameData.level;
    this.toMapLevel = gameData.toMap;
    this.hasSpendLoyalty = true;
    this.startTime = new Date();
    this.hasBoughtLoyalty = false;

    /** LEVEL: Retrieve level data */
    const levelsDataRaw = JSON.parse(Setup.getValue('settings.game.levelsJson').value) || {};
    const levelsData = levelsDataRaw.levels as ILevel[];
    this.level = levelsData.find((level: ILevel) => level.id === levelNumber) || null;

    /** BOARD: Collect data relevant to creation of the playable board */
    this.boardSettings = {
      powerups: [{
        frame: 5,
        type: 'horizontal'
      }, {
        frame: 6,
        type: 'vertical'
      }],
      itemSize: Scaling.getPixelbyDPR(60),
      swapSpeed: 200,
      fallSpeed: 800,
      destroySpeed: 800,
      board: {
        row: 6,
        columns: 6,
        spriteAmount: this.level?.gameplay.items.amount || 0,
        spriteSource: this.level?.gameplay.items.spritesheet || ''
      }
    }
  }

  preload() {
    /** SCENES: Launch parallel user interface if level is selected */
    if (this.level) {
      this.scene.launch('userinterface-game', { turns: this.level.gameplay.general.turns, scoreMax: this.level.gameplay.general.stars[0] });
    }
  }

  create() {
    /** RETURN TO MAP: return to the map, if no level is selected */
    if (!this.level) {
      this.shutdown();
      this.scene.start('map', { navigateToLevel: this.toMapLevel });

      return false;
    }

    /** BACKGROUND: Change background if Cape has double background setting active */
    if(Setup.getValue("settings.game.hasTwoBackground", false)) {
      const backgroundScene = this.scene.get('background') as Background;
      backgroundScene.setBackground('match_background');
    }

    /** PROGRESSION: default values of level progression */
    sessionSaveFile.create({
      type: ScoreGameTypes.LEVELS,
      stats: ['score', 'combos', 'playtime', 'turns']
    });
    sessionSaveFile.updateValue('map', 1);
    sessionSaveFile.updateValue('level', this.level.id);
    
    const highscore = globalSaveFile.getHighscore(1, this.level.id);
    sessionSaveFile.updateValue(ScoreDataDefault.HIGHSCORE, highscore)

    /** AUDIO  */
    const audioClick = this.sound.add("audio_select", { volume: 0.5 });
    const audioMatch = this.sound.add("audio_match", { volume: 0.4 });
    const audioWin = this.sound.add("audio_win", { volume: 0.5 });

    /** BOARD */
    const board = new Board(this, this.cameras.main.centerX, 0, this.boardSettings);

    /** EVENTS */
    this.game.events.emit('start', this.level.id);

    this.game.events.on('update_board_position', (data: any) => {
      const isSmallScreen = (this.cameras.main.centerY - board.getBounds().height / 2) <= data.threshold;
      board.setY(isSmallScreen ? data.threshold + board.getBounds().height / 2 : this.cameras.main.centerY);
      board.setBounds();

      this.events.emit('fill_board');
    })

    this.events.on('fill_board', () => board.fillBoard());

    this.events.on('add_progression', (data: IDefaultKeyVal) => {
      sessionSaveFile.incrementValue('combos', data.value);
    })

    this.events.on('update_turns', () => {
      sessionSaveFile.incrementValue('turns', 1);
      if (this.level) {
        const UIGame = this.scene.get('userinterface-game') as UIGame;
        UIGame.turns.updateText(this.level.gameplay.general.turns - parseInt(`${sessionSaveFile.getValue('turns')}`));
      }
    })

    this.events.on('update_score', (data: any) => {
      sessionSaveFile.incrementValue(ScoreDataDefault.TOTALSCORE, data.amount);
      this.events.emit('update_stars');
    })

    this.events.on('update_stars', () => {
      if (this.level) {
        const UIGame = this.scene.get('userinterface-game') as UIGame;
        const max = this.level.gameplay.general.stars[parseInt(`${sessionSaveFile.getValue(ScoreDataDefault.STARS)}`)] ? this.level.gameplay.general.stars[parseInt(`${sessionSaveFile.getValue(ScoreDataDefault.STARS)}`)] : this.level.gameplay.general.stars[2]
        UIGame.score.updateTextWithMax(parseInt(`${sessionSaveFile.getValue(ScoreDataDefault.TOTALSCORE)}`), max);

        if (parseInt(`${sessionSaveFile.getValue(ScoreDataDefault.TOTALSCORE)}`) >= this.level.gameplay.general.stars[parseInt(`${sessionSaveFile.getValue(ScoreDataDefault.STARS)}`)]) {
          sessionSaveFile.incrementValue(ScoreDataDefault.STARS, 1);
          UIGame.stars.updateText(parseInt(`${sessionSaveFile.getValue(ScoreDataDefault.STARS)}`), 3);
        }
      }
    })

    this.events.on('end_turn', (data: IDefaultKeyVal) => {
      const isGameOver = this.isGameOver();
      if (isGameOver) {
        if(sessionSaveFile.getValue(ScoreDataDefault.STARS) <= 0){
          this.hasBoughtLoyalty ? this.shutdown() : this.halt();
        }else {
          this.shutdown()
        }
      } else {
        this.events.emit('next_turn');
      }
    })

    /** CONTROLS */
    const inputController = new InputController({
      target: this.input,
      threshold: this.boardSettings.itemSize,
      callbackDown: (e: IInputControllerCallback) => {
        const row = Math.floor((e.event.y - (board.bounds.top || 0)) / this.boardSettings.itemSize);
        const col = Math.floor((e.event.x - (board.bounds.left || 0)) / this.boardSettings.itemSize);
        board.itemSelect(col, row);
      },
      callbackUp: (e: IInputControllerCallback) => {
        if (board.canPick) {
          const previousSelectedItem = board.match3Controller.getSelectedItem() as IMatch3Item;
          let currentSelectedItem = { row: previousSelectedItem.row, col: previousSelectedItem.column };

          if (previousSelectedItem) {
            if (e.direction == "up") {
              currentSelectedItem = { col: previousSelectedItem.column, row: previousSelectedItem.row - 1 };
            }
            if (e.direction == "down") {
              currentSelectedItem = { col: previousSelectedItem.column, row: previousSelectedItem.row + 1 };
            }
            if (e.direction == "left") {
              currentSelectedItem = { col: previousSelectedItem.column - 1, row: previousSelectedItem.row };
            }
            if (e.direction == "right") {
              currentSelectedItem = { col: previousSelectedItem.column + 1, row: previousSelectedItem.row };
            }

            board.itemSelect(currentSelectedItem.col, currentSelectedItem.row);
          }
        }
      }
    });
  }

  isGameOver() {
    return this.level ? (this.level.gameplay.general.turns - parseInt(`${sessionSaveFile.getValue('turns')}`)) === 0 : true;
  }

  halt() {
    if (this.scene.isPaused()) {
      return;
    }
    this.scene.pause();

    /** LOYALTY: ask user to pay loyalty to keep playing */
    this.game.events.emit("halt", async (restore: any) => {
      if (restore > 0) {
        this.hasBoughtLoyalty = true;

        sessionSaveFile.updateValue('turns', parseInt(`${sessionSaveFile.getValue('turns')}`) - restore);

        if (this.level) {
          const UIGame = this.scene.get('userinterface-game') as UIGame;
          UIGame.turns.updateText(this.level.gameplay.general.turns - parseInt(`${sessionSaveFile.getValue('turns')}`));
        }
        this.scene.resume();
        this.events.emit('next_turn');
      }
      else {
        this.shutdown();
      }
    });
  }


  shutdown() {
    /** SCENES: Cleanup */
    const UIGame = this.scene.get('userinterface-game') as UIGame;
    UIGame.scene.stop();

    /** EVENTS: Cleanup */
    this.events.off('add_progression');
    this.events.off('update_turns');
    this.events.off('update_score');
    this.events.off('update_stars');
    this.events.off('end_turn');
    this.events.off('next_turn');
    this.game.events.off('update_board_position');
    this.events.off('fill_board');


    /** END LEVEL */
    if (this.level) {
      this.scene.stop();

      /** PROGRESSION: update playtime */
      sessionSaveFile.updateValue("playtime", new Date().getTime() - this.startTime.getTime())
      sessionSaveFile.updateValue('score', sessionSaveFile.getValue(ScoreDataDefault.TOTALSCORE))

      const result = scoreController.getResult();

      this.game.events.emit('end', result);
    }
  }
}
