import Setup from "@/helpers/Setup";
import Constants from "../../../configs/constants";
import Scaling from "../../../configs/scaling";

export default class Turns extends Phaser.GameObjects.Container {
  textNumber:Phaser.GameObjects.Text;
  textPoints:Phaser.GameObjects.Text;

  constructor(scene: Phaser.Scene, x: number, y: number, startingTurns?: number) {
    super(scene, x, y);
    this.scene.add.existing(this);

    const background = this.scene.add.image(0, 0, "background_ui_turns").setOrigin(0.5, 0);
  
    this.textNumber = this.scene.add.text(0, background.getBounds().centerY - Scaling.getPixelbyDPR(-4), startingTurns?.toString() || '0', {
      fontFamily: Constants.FONT_BOLD,
      fontSize: `${Scaling.getPixelbyDPR(24)}px`,
      color: Constants.COLOR_BLACK_HEX
    }).setOrigin(0.5, 1);

  
    this.textPoints = this.scene.add.text(0, background.getBounds().centerY + Scaling.getPixelbyDPR(2), `${Setup.getCopy('general.turns')}`, {
      fontFamily: Constants.FONT_REGULAR,
      fontSize: `${Scaling.getPixelbyDPR(12)}px`,
      color: Constants.COLOR_BLACK_HEX
    }).setOrigin(0.5, 0);

    this.add([background, this.textNumber, this.textPoints]);
  }

  updateText(turns: number){
    this.textNumber.setText(`${turns}`);
    this.textPoints.setText(turns > 1 ? `${Setup.getCopy('general.turns')}` : `${Setup.getCopy('general.turn')}`);
  }
}