import BaseMapMarker from "./_baseMarker";

export default class RoadMapMarker extends BaseMapMarker {
  constructor(scene: Phaser.Scene, x: number, y: number, type: string, mapID: number, level: number) {
    super(scene, x, y, type, mapID, level);
    this.scene.add.existing(this);

    const spriteContainer = this.createSprite(undefined, 'map_marker_road');

    this.add([spriteContainer]);
  }
}