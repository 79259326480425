import Phaser from "phaser";
import Score from "../../components/containers/userinterface/score";
import Stars from "../../components/containers/userinterface/stars";
import Turns from "../../components/containers/userinterface/turns";
import Scaling from "../../configs/scaling";

export default class UIGame extends Phaser.Scene {
  score!: Score;
  stars!: Stars;
  turns!: Turns;
  startingTurns!: number;
  startingScoreMax!: number

  constructor() {
    super({ key: "userinterface-game" });
  }

  init(params: any) {
    this.startingTurns = params.turns;
    this.startingScoreMax = params.scoreMax
  }

  create() {
    /** GAME OBJECTS */
    this.turns = new Turns(this, this.cameras.main.centerX, Scaling.getPixelbyDPR(72), this.startingTurns);
    this.score = new Score(this, Scaling.getPixelbyDPR(16), this.turns.getBounds().centerY);
    this.stars = new Stars(this, this.cameras.main.width - Scaling.getPixelbyDPR(16), this.turns.getBounds().centerY);

    if(this.startingScoreMax !== undefined)
      this.score.updateTextWithMax(0, this.startingScoreMax)

    /** EVENTS */
    this.game.events.emit('update_board_position', { threshold: this.turns.getBounds().bottom + Scaling.getPixelbyDPR(20) })
  }
}
