export default class BoardItem extends Phaser.GameObjects.Container {
  tweenWiggle: Phaser.Tweens.Tween;
  rexShaker: any;

  constructor(scene: Phaser.Scene, key: string, destroySpeed: number) {
    super(scene, 0, 0);
    this.scene.add.existing(this);

    const background = this.scene.add.sprite(0, 0, 'game_board_item_background').setName('background');
    const icon = this.scene.add.sprite(0, 0, key).setName('icon');

    this.tweenWiggle = this.scene.tweens.add({
      targets: this,
      angle: -10,
      paused: true,
      yoyo: true,
      repeat: -1,
      duration: 400,
      ease: "Cubic.easeInOut"
    });

    const rexShakerPlugin = this.scene.plugins.get('rexShakePosition') as any;
    this.rexShaker = rexShakerPlugin.add(this, { 
      duration: (destroySpeed / 1.8) / 2, 
      mode: 'effect'
    });

    this.add([background, icon]);
  }

  updateFrame(frame: number) {
    const icon = this.getByName('icon') as Phaser.GameObjects.Sprite;
    icon.setFrame(frame);
  }

  kill() {
    this.setVisible(false).setActive(false);
  }

  revive(x: number, y: number, frame: number) {
    const icon = this.getByName('icon') as Phaser.GameObjects.Sprite;

    icon.setFrame(frame);

    this.setAlpha(1);
    this.setScale(1);
    this.setPosition(x, y);
    this.setData('isSpecial', false);
    this.setVisible(true).setActive(true);
  }
}