import Phaser from "phaser";

export default class Background extends Phaser.Scene {
  background!: Phaser.GameObjects.Image;
  music?: Phaser.Sound.BaseSound;
  rexShaker!: any;

  constructor() {
    super({key: "background"});
  }
  
  create() {
    this.cameras.main.setBackgroundColor(0xFFFFFF);
    this.background = this.add.image(0, 0, 'game_background').setOrigin(0, 0);
    this.background.setDisplaySize(this.cameras.main.width, this.cameras.main.height)
    
    const rexShakerPlugin = this.plugins.get('rexShakePosition') as any;
    this.rexShaker = rexShakerPlugin.add(this);

    // Notify that game has booted
    this.game.events.emit("booted");
  }
  
  setColor(color: number) {
    this.cameras.main.setBackgroundColor(color ? color : 0xFFFFFF);
  }

  setBackground(texture: string){
    this.background.setTexture(texture);
  }
  
  playMusic() {
    this.stopMusic();
    
    //Create music instance & play
    this.music = this.sound.add("theme");
    this.music.play({loop: true, volume: 0.05});
  }
  
  stopMusic() {
    if(this.music) {
      this.music.destroy();
    }
  }
  
}