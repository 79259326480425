import Scaling from "@/game/match3/configs/scaling";
import Setup from "@/helpers/Setup";
import globalSaveFile from "@/utils/game/GlobalSaveFile";
import LockedMapMarker from "./markers/lockedMarker";
import NewMapMarker from "./markers/newMarker";
import RoadMapMarker from "./markers/roadMarker";
import UnlockedMapMarker from "./markers/unlockedMarker";

export default class MapLevelPath extends Phaser.GameObjects.Container {
  levelsPositions: number[];
  mapID: number;

  constructor(scene: Phaser.Scene, x: number, y: number, mapID: number) {
    super(scene, x, y);
    this.scene.add.existing(this);

    /** SETTINGS */
    this.mapID = mapID;
    const levelsDataRaw = JSON.parse(Setup.getValue('settings.game.levelsJson').value) || {};
    const levelsData = levelsDataRaw.levels.slice().reverse();

    this.levelsPositions = this.getSinWavePositions((levelsData.length * 2) - 1, 1.68 * levelsData.length, Scaling.getPixelbyDPR(64));
    const currentLevel = globalSaveFile.getNextLevelInMap(this.mapID);

    /** MAP: Create markers */
    for (const data of levelsData) {
      const completedLevel = globalSaveFile.getLevel(this.mapID, data.id);

      if(completedLevel){
        this.createMarker("unlocked", data.id);
      }else {
        if(data.id== currentLevel){
          this.createMarker("new", data.id);
        }else {
          this.createMarker("locked", data.id);                    
        }
      }

      if (data.id != levelsData[levelsData.length - 1].id) {
        this.createMarker("road");
      }

    }
    
  }

  getSinWavePositions(pointCount: number, frequency?: number, amplitude?: number, offset?: number) {
    frequency = frequency || 1;
    amplitude = amplitude || 1;
    offset = offset || 0;
    const points = [];

    for (let i = 0; i < pointCount; i++) {
      points.push(amplitude * Math.sin((i / pointCount) * frequency)) + offset;
    }

    return points;
  }

  createMarker(type: string, level?: number) {
    
    let marker = null;
    
    switch (type) {
      case 'road':
        marker = new RoadMapMarker(this.scene, 0, 0, type, this.mapID, -1);
        break
      case 'unlocked':
        marker = new UnlockedMapMarker(this.scene, 0, 0, type, this.mapID, level || 0);
        break;
      case 'locked':
        marker = new LockedMapMarker(this.scene, 0, 0, type, this.mapID, level || 0);
        break;
      case 'new':
        marker = new NewMapMarker(this.scene, 0, 0, type, this.mapID, level || 0);
        break;
    }

    if (marker) {
      const currentMarkerSprite = marker.getByName('spriteContainer') as Phaser.GameObjects.Container;
      const previousMarker = this.last as Phaser.GameObjects.Container;
      const previousMarkerSprite = previousMarker ? previousMarker.getByName('spriteContainer') as Phaser.GameObjects.Container : null;

      marker.setPosition(
        this.scene.cameras.main.centerX + this.levelsPositions[this.length],
        previousMarkerSprite ? previousMarkerSprite.getBounds().bottom + (currentMarkerSprite.getBounds().height / 2) + Scaling.getPixelbyDPR(38) : 0
      )
      
      this.add(marker);
    }
  }
}