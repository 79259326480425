import { PageName } from "@/utils/Constants";
import Setup from "@/helpers/Setup";
import Phaser from "phaser";
import Score from "../../components/containers/userinterface/score";
import Stars from "../../components/containers/userinterface/stars";
import Scaling from "../../configs/scaling";
import { ISetupGameSettings } from "../../interfaces/core/ISetupGameSettings";
import Map from "../map";
import Constants from "../../configs/constants";

export default class UIMap extends Phaser.Scene {
  totalScore!: number;
  totalStars!: number;

  constructor() {
    super({ key: "userinterface-map" });
  }

  init(params: any) {
    this.totalScore = params.score;
    this.totalStars = params.stars;
  }

  create() {
    const score = new Score(this, Scaling.getPixelbyDPR(16), Scaling.getPixelbyDPR(96));
    const stars = new Stars(this, this.cameras.main.width - Scaling.getPixelbyDPR(16), score.getBounds().centerY);
    score.updateText(this.totalScore);
    stars.updateText(this.totalStars);

    
    const startButton = this.add.image(this.cameras.main.centerX, this.cameras.main.height - Scaling.getPixelbyDPR(10), 'button')
    startButton.setInteractive().setOrigin(0.5, 1)
    startButton.on('pointerup', () => {
      const mapScene = this.scene.get('map')
      mapScene.events.emit('start_level_latest')
    })

    const startTextStyle: Phaser.Types.GameObjects.Text.TextStyle = {
      fontFamily: Constants.FONT_BOLD,
      fontSize: `${Scaling.getPixelbyDPR(14)}px`,
      color: Setup.getValue('design.text.textAlternate', 0x00000)
    }
    const startText = this.add.text(startButton.getCenter().x, startButton.getCenter().y, Setup.getCopy('general.play'), startTextStyle).setOrigin(0.5, 0.5)


    /** BUTTONS: Add buttons based upon cape game settings */
    const gameSettings = Setup.data.settings.game as ISetupGameSettings;

    if (gameSettings.showTopBarBackButton) {
      const buttonBack = this.add.image(Scaling.getPixelbyDPR(16), Scaling.getPixelbyDPR(16), 'button_back').setOrigin(0, 0);
      buttonBack.setInteractive().on('pointerup', () => {
        const mapScene = this.scene.get('map') as Map;
        mapScene.shutdown();

        this.game.events.emit('route', { route: PageName.LAUNCH })
      });
    }

    if (gameSettings.showTopBarInfoButton) {
      const buttonSettings = this.add.image(this.cameras.main.width - Scaling.getPixelbyDPR(16), Scaling.getPixelbyDPR(16), 'button_settings').setOrigin(1, 0);
      buttonSettings.setInteractive().on('pointerup', () => {
        const mapScene = this.scene.get('map') as Map;
        mapScene.shutdown();

        this.game.events.emit('route', { route: PageName.SETTINGS })
      });
    }
  }
}
