export default class Constants {

    /** FONTS */
    static FONT_BOLD = "font_bold";
    static FONT_REGULAR = "font_regular";

    /** COLORS */
    static COLOR_YELLOW_HEX = '#FFB300';
    static COLOR_YELLOW_INT = 0xFFB300;
    static COLOR_BLACK_HEX = '#000000';
    static COLOR_BLACK_INT = 0x000000;
    static COLOR_WHITE_HEX = '#FFFFFF';
    static COLOR_WHITE_INT = 0xffffff;    
}