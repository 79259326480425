import Constants from "../../../configs/constants";
import Scaling from "../../../configs/scaling";

export default class Score extends Phaser.GameObjects.Container {
  textScore: Phaser.GameObjects.Text;
  rexShaker: any;

  constructor(scene: Phaser.Scene, x: number, y: number) {
    super(scene, x, y);
    this.scene.add.existing(this);

    const background = this.scene.add.image(0, 0, "background_ui_score").setOrigin(0, 0.5).setName('background');
    this.textScore = this.scene.add.text(background.getCenter().x + Scaling.getPixelbyDPR(20), 0, '0', {
      fontFamily: Constants.FONT_BOLD,
      fontSize: `${Scaling.getPixelbyDPR(16)}px`,
      color: Constants.COLOR_BLACK_HEX,
      align: 'center'
    }).setOrigin(0.5, 0.5);

    const rexShakerPlugin = this.scene.plugins.get("rexShakePosition") as any;
    this.rexShaker = rexShakerPlugin.add(this);

    this.add([background, this.textScore]);
  }

  updateText(score: number) {
    this.textScore.setText(`${score}`);
  }

  updateTextWithMax(score: number, scoreToNextStart: number) {
    this.textScore.setText(`${score}/${scoreToNextStart}`);
    
  }
}