import Constants from "@/game/match3/configs/constants";
import Scaling from "@/game/match3/configs/scaling";
import Setup from "@/helpers/Setup";
import scoreController from "@/utils/game/ScoreController";
import BaseMapMarker from "./_baseMarker";

export default class NewMapMarker extends BaseMapMarker {
  constructor(scene: Phaser.Scene, x: number, y: number, type: string, mapID: number, level: number) {
    super(scene, x, y, type, mapID, level);
    this.scene.add.existing(this);

    const spriteContainer = this.createSprite('map_marker_open');
    const sprite = spriteContainer.getByName('sprite') as Phaser.GameObjects.Sprite;
    this.add([spriteContainer]);

    /** REQUIRED STARS: Check if user has enough stars to play level */
    const starsRequired = scoreController.getRequiredStars(mapID, level);

    /** CAN PLAY: User has enough stars to play */
    if (starsRequired <= 0) {
      sprite.setInteractive().on("pointerup", () => this.startLevel(level));
    }

    /** CANNOT PLAY: User hasn't got enough stars, respond with feedback */
    else {
      // sprite.setInteractive().on("pointerdown", () => this.scene.scene.launch("PopupScene", {
      //   parent: this,
      //   type: "dialog",
      //   detail: { title: "HELLO PLACEHOLDER YOU STILL NEED [STARSNEEDED]".replace("[STARSNEEDED]", `${starsRequired}`) }
      // }));

      const starsRequiredContainer = this.createStarsRequired(sprite, starsRequired);
      this.add([...starsRequiredContainer]);
    }


  }

  createSprite(frame?: string, key = "map_markers") {
    const spriteContainer = super.createSprite(frame, key)

    /** TEXT */
    const levelText = this.scene.add.text(0, 0, `${this.level}`, {
      fontSize: `${Scaling.getPixelbyDPR(16)}px`,
      fontFamily: Constants.FONT_BOLD,
      color: Setup.getValue('design.text.color', 0x00000)
    }).setOrigin(0.5, 0.5)

    spriteContainer.add(levelText)

    return spriteContainer
  }
}