import Setup from "@/helpers/Setup";
import Phaser from "phaser";
import Constants from "../../configs/constants";
import Scaling from "../../configs/scaling";
import { parseHexColor } from "@/game/shared/utils/Helpers";

export default class PopupLevelStart extends Phaser.Scene {
  level!: number;
  stars!: number;
  playtime!: string;
  highscore!: number;
  container!: Phaser.GameObjects.Container;
  onCancel!: () => void;
  onStart!: () => void;

  constructor() {
    super({ key: "popup-levelstart" });
  }

  init(params: any) {
    /** SETTINGS */
    this.level = params.level || 0;
    this.stars = params.stars || 0;
    this.playtime = params.playtime || '0:00';
    this.highscore = params.highscore || 0;
    this.onCancel = params.onCancel;
    this.onStart = params.onStart;
  }

  create() {
    /** GAME OBJECTS: Zone an interactive area behind the whole popup that closes the scene */
    const zone = this.add.zone(this.cameras.main.centerX, this.cameras.main.centerY, this.cameras.main.width, this.cameras.main.height);
    zone.setInteractive().on('pointerup', () => this.scene.stop());

    /** GAME OBJECTS: Container */
    this.container = this.add.container(this.cameras.main.centerX, this.cameras.main.centerY);

    /** GAME OBJECT: Title */
    const title = this.add.text(0, Scaling.getPixelbyDPR(32), `${Setup.getCopy('general.level')} ${this.level}`, {
      fontFamily: Constants.FONT_REGULAR,
      fontSize: `${Scaling.getPixelbyDPR(24)}px`,
      color: Setup.getValue('settings.game.textcolor', 0xffffff)
    }).setOrigin(0.5, 0);

    /** GAME OBJECT: Stars */
    const starsContainer = this.createStarsContainer(0, title.getBounds().bottom + Scaling.getPixelbyDPR(54));

    /** GAME OBJECTS: Details */
    const divider = this.createDividerSprite(starsContainer.getBounds().centerX, starsContainer.getBounds().bottom + Scaling.getPixelbyDPR(42)).setOrigin(0.5, 0);
    const highscoreContainer = this.createHighscoreContainer(divider.getBounds().left - Scaling.getPixelbyDPR(24), divider.getBounds().centerY, this.highscore);
    const playtimeContainer = this.createPlaytimeContainer(divider.getBounds().right + Scaling.getPixelbyDPR(24), divider.getBounds().centerY, this.playtime);

    /** GAME OBJECTS: Buttons */
    const buttonStart = this.createButtonContainer(0, divider.getBounds().bottom + Scaling.getPixelbyDPR(42), `${Setup.getCopy('general.play')}`);
    const buttonStartImage = buttonStart.getByName('image') as Phaser.GameObjects.Sprite;
    buttonStartImage.setInteractive().on('pointerup', () => { if (this.onStart) this.onStart() });

    const buttonCancel = this.createButtonContainer(0, buttonStart.getBounds().bottom + Scaling.getPixelbyDPR(6), `${Setup.getCopy('general.cancel')}`, true);
    const buttonCancelImage = buttonCancel.getByName('image') as Phaser.GameObjects.Sprite;
    buttonCancelImage.setInteractive().on('pointerup', () => { if (this.onCancel) this.onCancel() });

    /** CONTAINER: add all current game objects to container */
    this.container.add([title, starsContainer, divider, highscoreContainer, playtimeContainer, buttonStart, buttonCancel]);

    /** GAME OBJECT: Background */
    const background = this.createBackgroundSprite(0, 0).setOrigin(0.5, 0);
    this.container.add(background);

    /** CONTAINER: Sort background to the back */
    this.container.sendToBack(background);

    /** CONTAINER: reposition container to centerY */
    this.container.setY(this.container.y - this.container.getBounds().height / 2);

    /** CONTAINER: Add interactive with hit area, so input doesn't target zone underneath */
    this.container.setInteractive(new Phaser.Geom.Rectangle(-this.container.getBounds().width / 2, 0, this.container.getBounds().width, this.container.getBounds().height), Phaser.Geom.Rectangle.Contains);
  }

  createStarsContainer(x: number, y: number) {
    const stars = this.add.container(x, y);
    for (let i = 0; i < 3; i++) {
      const star = this.add.image(0, 0, i <= (this.stars - 1) ? 'popup_star_filled' : 'popup_star_empty').setOrigin(0.5, 0);
      star.setPosition((star.getBounds().width / 2) + (i * (star.getBounds().width + Scaling.getPixelbyDPR(4))), i === 1 ? -Scaling.getPixelbyDPR(20) : 0);
      stars.add(star);
    }
    stars.setX(-stars.getBounds().width / 2);

    return stars;
  }

  createDividerSprite(x: number, y: number) {
    if (!this.textures.exists("texture_popup_levelstart_divider")) {
      const dividerTexture = this.add.graphics();
      dividerTexture.fillStyle(Phaser.Display.Color.HexStringToColor(Setup.getValue('settings.game.textcolor', `#0xffffff`)).color);
      dividerTexture.fillRect(0, 0, Scaling.getPixelbyDPR(1), Scaling.getPixelbyDPR(20));
      dividerTexture.generateTexture("texture_popup_levelstart_divider", Scaling.getPixelbyDPR(2), Scaling.getPixelbyDPR(20));
    }
    const divider = this.add.image(x, y, "texture_popup_levelstart_divider");

    return divider;
  }

  createHighscoreContainer(x: number, y: number, highscore: number) {
    const highscoreContainer = this.add.container(x, y);
    const highscoreText = this.add.text(0, 0, `${Setup.getCopy('general.highscore')}`, {
      fontFamily: Constants.FONT_REGULAR,
      fontSize: `${Scaling.getPixelbyDPR(14)}px`,
      color: Setup.getValue('settings.game.textcolor', 0xffffff),
      wordWrap: {width: Scaling.getPixelbyDPR(128), useAdvancedWrap: true},
      align: 'center'
    }).setOrigin(0.5, 0);
    const highscoreAmount = this.add.text(highscoreText.getBounds().centerX, highscoreText.getBounds().bottom + Scaling.getPixelbyDPR(4), `${highscore}`, {
      fontFamily: Constants.FONT_BOLD,
      fontSize: `${Scaling.getPixelbyDPR(18)}px`,
      color: Setup.getValue('settings.game.textcolor', 0xffffff),
      wordWrap: {width: Scaling.getPixelbyDPR(128), useAdvancedWrap: true},
      align: 'center'
    }).setOrigin(0.5, 0);

    highscoreContainer.add([highscoreText, highscoreAmount]);
    highscoreContainer.setPosition(highscoreContainer.x - highscoreContainer.getBounds().width / 2, highscoreContainer.y - highscoreContainer.getBounds().height / 2);

    return highscoreContainer;
  }

  createPlaytimeContainer(x: number, y: number, playtime: string) {
    const playtimeContainer = this.add.container(x, y);
    const playtimeText = this.add.text(0, 0, `${Setup.getCopy('general.playTime')}`, {
      fontFamily: Constants.FONT_REGULAR,
      fontSize: `${Scaling.getPixelbyDPR(14)}px`,
      color: Setup.getValue('settings.game.textcolor', 0xffffff),
      wordWrap: {width: Scaling.getPixelbyDPR(128), useAdvancedWrap: true},
      align: 'center'
    }).setOrigin(0.5, 0);
    const playtimeAmount = this.add.text(playtimeText.getBounds().centerX, playtimeText.getBounds().bottom + Scaling.getPixelbyDPR(4), playtime, {
      fontFamily: Constants.FONT_BOLD,
      fontSize: `${Scaling.getPixelbyDPR(18)}px`,
      color: Setup.getValue('settings.game.textcolor', 0xffffff),
      wordWrap: {width: Scaling.getPixelbyDPR(128), useAdvancedWrap: true},
      align: 'center'
    }).setOrigin(0.5, 0);

    playtimeContainer.add([playtimeText, playtimeAmount]);
    playtimeContainer.setPosition(playtimeContainer.x + playtimeContainer.getBounds().width / 2, playtimeContainer.y - playtimeContainer.getBounds().height / 2);

    return playtimeContainer;
  }

  createButtonContainer(x: number, y: number, text: string, alternate?: boolean) {
    const buttonContainer = this.add.container(x, y);

    let rounded = 0;
    switch (Setup.getValue('design.buttons.borderRadius', 0) as string) {
      case "small":
        rounded = Scaling.getPixelbyDPR(10);
        break
      case "medium":
        rounded = Scaling.getPixelbyDPR(15);
        break;
      case "large":
        rounded = Scaling.getPixelbyDPR(21)
        break;
    }

    /** Choose style */
    if (!alternate) {
      if (!this.textures.exists("texture_popup_levelstart_button")) {
        const buttonTexture = this.make.graphics({ x: 0, y: 0 });
        buttonTexture.fillStyle(parseHexColor(Setup.getValue('design.buttons.primary.backgroundColor', `#0xffffff`)).color, parseHexColor(Setup.getValue('design.buttons.primary.backgroundColor', `#0xffffff`)).alpha);

        buttonTexture.fillRoundedRect(0, 0, Scaling.getPixelbyDPR(303), Scaling.getPixelbyDPR(42), rounded);

        buttonTexture.generateTexture("texture_popup_levelstart_button", Scaling.getPixelbyDPR(303), Scaling.getPixelbyDPR(42));
      }

      const button = this.add.image(0, 0, 'texture_popup_levelstart_button').setOrigin(0.5, 0).setName('image');
      const buttonText = this.add.text(button.getBounds().centerX, button.getBounds().centerY, text, {
        fontFamily: Constants.FONT_BOLD,
        fontSize: `${Scaling.getPixelbyDPR(16)}px`,
        color: Setup.getValue('settings.game.primarytextcolor', 0xffffff)
      }).setOrigin(0.5);

      buttonContainer.add([button, buttonText]);
    }

    else if (alternate) {
      if (!this.textures.exists("texture_popup_levelstart_button_alternate")) {
        const buttonTexture = this.make.graphics({ x: 0, y: 0 });
        buttonTexture.fillStyle(Phaser.Display.Color.HexStringToColor(Setup.getColor('button')).color, 0);
        buttonTexture.lineStyle(Scaling.getPixelbyDPR(1), Phaser.Display.Color.HexStringToColor(Setup.getValue('design.text.color', `#0xffffff`)).color, 1);

        buttonTexture.fillRoundedRect(0, 0, Scaling.getPixelbyDPR(280), Scaling.getPixelbyDPR(42), rounded);
        buttonTexture.strokeRoundedRect(0, 0, Scaling.getPixelbyDPR(280), Scaling.getPixelbyDPR(42), rounded);

        buttonTexture.generateTexture("texture_popup_levelstart_button_alternate", Scaling.getPixelbyDPR(280), Scaling.getPixelbyDPR(42));
      }

      const button = this.add.image(0, 0, 'texture_popup_levelstart_button_alternate').setOrigin(0.5, 0).setName('image');
      const buttonText = this.add.text(button.getBounds().centerX, button.getBounds().centerY, text, {
        fontFamily: Constants.FONT_BOLD,
        fontSize: `${Scaling.getPixelbyDPR(16)}px`,
        color: Setup.getValue('settings.game.textcolorAlternate', 0xFFFFFF)
      }).setOrigin(0.5);

      buttonContainer.add([button, buttonText]);
    }

    return buttonContainer;
  }

  createBackgroundSprite(x: number, y: number) {
    if (!this.textures.exists("texture_popup_levelstart_background")) {
      const backgroundTexture = this.make.graphics({ x: 0, y: 0 });
      backgroundTexture.fillStyle(Constants.COLOR_WHITE_INT, 1);
      backgroundTexture.fillRoundedRect(0, 0, this.container.getBounds().width + Scaling.getPixelbyDPR(32), this.container.getBounds().height + Scaling.getPixelbyDPR(48));
      backgroundTexture.generateTexture("texture_popup_levelstart_background", this.container.getBounds().width + Scaling.getPixelbyDPR(32), this.container.getBounds().height + Scaling.getPixelbyDPR(48));
    }
    const background = this.add.image(x, y, "texture_popup_levelstart_background");

    return background;
  }
}
