import Match3 from "../../../controllers/match3";
import { ISetupGameSettings } from "../../../interfaces/core/ISetupGameSettings";
import { IBoardSettings } from "../../../interfaces/board/IBoardSettings";
import { IMatch3Item, IMatch3PowerupItems } from "../../../interfaces/board/IMatch3";
import { IDefaultCoords, IDefaultKeyVal } from "../../../interfaces/core/IDefault";
import BoardItems from "../../groups/boardItems";
import UIGame from "../../../states/userinterfaces/game";
import BoardItem from "../board/boardItem";
import Background from "../../../states/background";
import Score from "../userinterface/score";
import Setup from "@/helpers/Setup";

export default class Board extends Phaser.GameObjects.Container {
  match3Controller: Match3;
  background: Phaser.GameObjects.Image;
  bounds!: IDefaultCoords;
  items: BoardItems;
  particle:Phaser.GameObjects.Particles.ParticleEmitterManager;
  emitter: Phaser.GameObjects.Particles.ParticleEmitter;
  settings: IBoardSettings;
  canPick: boolean;

  constructor(scene: Phaser.Scene, x: number, y: number, settings: IBoardSettings) {
    super(scene, x, y);
    this.scene.add.existing(this);

    /** SETTINGS */
    const gameSettings = Setup.data.settings.game as ISetupGameSettings;
    this.canPick = false;
    this.settings = settings;

    /** GAME OBJECTS: Background */
    this.background = this.scene.add.image(0, 0, 'game_board_background').setName('background');

    if (gameSettings.showBoardTopVisual) {
      const backgroundTop = this.scene.add.image(0, this.background.getBounds().top, 'game_board_top').setOrigin(0.5, 1);
      this.add(backgroundTop);
    }

    if (gameSettings.showBoardBottomVisual) {
      const backgroundBottom = this.scene.add.image(0, this.background.getBounds().bottom, 'game_board_bottom').setOrigin(0.5, 0);
      this.add(backgroundBottom);
    }
    this.add(this.background);

    /** GAME OBJECTS: Items */
    this.items = new BoardItems(this.scene, settings.board.spriteSource, settings.destroySpeed);

    /** GAME OBJECTS: Particles */
    this.particle = this.scene.add.particles('game_board_particles');
    this.emitter = this.particle.createEmitter({
      x: 400,
      y: 300,
      speed: { min: -500, max: 500 },
      angle: { min: 0, max: 360 },
      scale: { start: 0.6, end: 0 },
      frequency: -1,
      quantity: 30,
      lifespan: 1500,
      gravityY: 800,
      frame:this.particle.frameNames
    });

    this.add([this.particle, ...this.items.getChildren()])

    /** MATCH3: Add match3 logic controller based upon settings */
    this.match3Controller = new Match3({
      rows: settings.board.row,
      columns: settings.board.columns,
      items: settings.board.spriteAmount
    });

    /** EVENTS */
    this.scene.events.on('next_turn', (data: IDefaultKeyVal) => {
      this.nextTurn();
    })
  }

  setBounds() {
    this.bounds = {
      top: this.background.getBounds().top,
      left: this.background.getBounds().left,
      x: -(this.background.getBounds().width / 2),
      y: -(this.background.getBounds().height / 2)
    }
  }

  rearrangeBoard(items: IMatch3Item[]) {
    this.match3Controller.removeMatches(items);

    const fallingItems = this.match3Controller.arrangeBoardAfterMatch();
    fallingItems.forEach(item => {
      this.scene.tweens.add({
        targets: this.match3Controller.customDataOf(item.row, item.column),
        y: this.match3Controller.customDataOf(item.row, item.column).y + item.deltaRow * this.settings.itemSize,
        duration: this.settings.fallSpeed,
        ease: "Bounce"
      });
    });
  }


  fillBoard() {
    const boardEmptySpaces = this.match3Controller.getBoardEmptySpaces();
    let boardEmptySpacesAmount = boardEmptySpaces.length;
    boardEmptySpaces.forEach(space => {
      const item = this.items.spawn(
        this.bounds.x + (this.settings.itemSize * space.column) + (this.settings.itemSize / 2),
        this.bounds.y + (this.settings.itemSize * (space.row - space.deltaRow + 1)) - this.settings.itemSize / 2,
        this.match3Controller.valueAt(space.row, space.column) || 0
      )
      this.match3Controller.setCustomData(space.row, space.column, item);

      // Tween items into place //
      this.scene.tweens.add({
        targets: item,
        y: this.bounds.y + (this.settings.itemSize * space.row) + (this.settings.itemSize / 2),
        duration: this.settings.fallSpeed,
        ease: 'Bounce',
        onComplete: () => {
          boardEmptySpacesAmount--;
          if (boardEmptySpacesAmount == 0) {
            this.endOfTurn();
          }
        }
      });
    });
  }

  itemSelect(col: number, row: number) {
    if (this.canPick) {
      // Check if clicked inside board //
      if (this.match3Controller.validPick(row, col)) {
        let selectedItem = this.match3Controller.getSelectedItem();

        // First Item: Select //
        if (!selectedItem) {
          this.match3Controller.customDataOf(row, col).setScale(1.2);
          this.match3Controller.customDataOf(row, col).setDepth(1);
          this.match3Controller.setSelectedItem(row, col);

          // Animation: Wiggle - start //    
          this.match3Controller.customDataOf(row, col).tweenWiggle.play();
        }

        // Second Item: Check //
        else {
          selectedItem = selectedItem as IMatch3Item;

          // Animation: Wiggle - stop //
          this.match3Controller.customDataOf(selectedItem.row, selectedItem.column).tweenWiggle.stop();
          this.match3Controller.customDataOf(selectedItem.row, selectedItem.column).setAngle(0);

          // Second Item: The Same //
          if (this.match3Controller.areTheSame(row, col, selectedItem.row, selectedItem.column)) {
            this.match3Controller.customDataOf(row, col).setScale(1);
            this.match3Controller.deselectItem();
          }

          // Second Item: Different //
          else if (this.match3Controller.areNext(row, col, selectedItem.row, selectedItem.column)) {
            this.match3Controller.customDataOf(selectedItem.row, selectedItem.column).setScale(1);
            this.match3Controller.deselectItem();
            this.swapItems(row, col, selectedItem.row, selectedItem.column, true);
          }
        }
      }
    }
  }

  swapItems(row: number, col: number, row2: number, col2: number, swapBack: boolean) {
    // Swap first and second item, return new positions //
    this.canPick = false;

    const items = this.match3Controller.swapItems(row, col, row2, col2);
    let itemsAmount = items.length;

    // Reposition swapped items //
    items.forEach(item => {
      this.scene.tweens.add({
        targets: this.match3Controller.customDataOf(item.row, item.column),
        x: this.match3Controller.customDataOf(item.row, item.column).x + this.settings.itemSize * item.deltaColumn,
        y: this.match3Controller.customDataOf(item.row, item.column).y + this.settings.itemSize * item.deltaRow,
        duration: this.settings.swapSpeed,
        onComplete: () => {
          itemsAmount--;
          if (itemsAmount == 0) {

            // Items: One of the items is a power-up //
            if (this.match3Controller.customDataOf(row, col).getData('isSpecial') || this.match3Controller.customDataOf(row2, col2).getData('isSpecial')) {
              if (this.match3Controller.customDataOf(row, col).getData('isSpecial') && this.match3Controller.customDataOf(row2, col2).getData('isSpecial')) {
                this.powerupRandom(row, col, row2, col2);
              } else {
                this.match3Controller.customDataOf(row, col).getData('isSpecial') ? this.powerupLine(row, col) : null;
                this.match3Controller.customDataOf(row2, col2).getData('isSpecial') ? this.powerupLine(row2, col2) : null;
              }
            }

            // Items: No Matching - swap items back to original positions, then reable picking //
            else if (!this.match3Controller.matchInBoard()) {
              swapBack ? this.swapItems(row, col, row2, col2, false) : this.canPick = true;
            }

            // Items: Matching //
            else {
              this.handleMatches();
              this.scene.events.emit('update_turns');
            }
          }
        }
      });
    });
  }

  handlePowerups(items: IMatch3Item[]) {
    this.handleNormalMatch(items);

    this.scene.time.delayedCall(this.settings.destroySpeed / 1.5, () => {
      this.rearrangeBoard([...items]);
      this.fillBoard();
    });
  }

  handleMatches() {
    // this.audio_match.play();

    /** MATCHES: Get full list of all matchable items */
    const matches = this.match3Controller.getMatchList();
    const matchesByType = {
      normal: [] as IMatch3Item[],
      combo: [] as IMatch3PowerupItems[]
    }

    const filteredMatches = this.filterMatches(matches);
    matchesByType.normal.push(...filteredMatches.normal);
    matchesByType.combo.push(...filteredMatches.combo);

    /** MATCHES CREATE POWERUPS */
    if (filteredMatches.combo.length) {
      this.handleComboMatch(matchesByType.combo);
    }

    if (filteredMatches.normal.length) {
      this.handleNormalMatch(matchesByType.normal);
    }

    this.scene.time.delayedCall(this.settings.destroySpeed / 1.5, () => {
      const matchesCombosReduced = matchesByType.combo.reduce((acc, value) => [...acc, ...value.items], [] as IMatch3Item[]);
      this.rearrangeBoard([...matchesByType.normal, ...matchesCombosReduced]);
      this.fillBoard();
    });
  }

  filterMatches(matches: IMatch3Item[]) {
    const normalMatches = [] as IMatch3Item[];
    const specialMatches = [] as IMatch3PowerupItems[];
    const horizontalMatch = {} as any;
    const verticalMatch = {} as any;

    matches.forEach(match => {
      const type = this.match3Controller.valueAt(match.row, match.column) as number;

      if (this.match3Controller.isPartOfHorizontalMatch(match.row, match.column)) {
        if (!horizontalMatch[type]) { horizontalMatch[type] = {} }
        if (!horizontalMatch[type][match.row]) { horizontalMatch[type][match.row] = [] }

        horizontalMatch[type][match.row].push(match);
      }

      else if (this.match3Controller.isPartOfVerticalMatch(match.row, match.column)) {
        if (!verticalMatch[type]) { verticalMatch[type] = {} }
        if (!verticalMatch[type][match.column]) { verticalMatch[type][match.column] = [] }

        verticalMatch[type][match.column].push(match);
      }
    });

    Object.keys(horizontalMatch).forEach(type => {
      Object.keys(horizontalMatch[type]).forEach(row => {
        if (horizontalMatch[type][row].length <= 3) {
          horizontalMatch[type][row].forEach((match: IMatch3Item) => normalMatches.push(match))
        } else {
          specialMatches.push({
            type: parseInt(type),
            direction: "horizontal",
            items: horizontalMatch[type][row]
          });
        }
      });
    });

    Object.keys(verticalMatch).forEach(type => {
      Object.keys(verticalMatch[type]).forEach(column => {
        if (verticalMatch[type][column].length <= 3) {
          verticalMatch[type][column].forEach((match: IMatch3Item) => normalMatches.push(match))
        } else {
          specialMatches.push({
            type: parseInt(type),
            direction: "vertical",
            items: verticalMatch[type][column]
          });
        }
      });
    });

    return { normal: normalMatches, combo: specialMatches }
  }

  handleComboMatch(matches: IMatch3PowerupItems[]) {
    matches.forEach(match => {
      const getCenterofMatch = match.items[Math.ceil(match.items.length / 2) - 1];
      const spriteCenter = this.match3Controller.customDataOf(getCenterofMatch.row, getCenterofMatch.column);
      const matchesFiltered = match.items.filter(item => item.row !== getCenterofMatch.row || item.column !== getCenterofMatch.column);
      match.items = matchesFiltered;

      const specialPool = matchesFiltered.map((item: any) => item = this.match3Controller.customDataOf(item.row, item.column));

      this.bringToTop(this.particle);

      this.bringToTop(spriteCenter);
      this.emitter.explode(25, spriteCenter.x, spriteCenter.y);
      specialPool.forEach(item => {
        this.bringToTop(item);
        this.emitter.explode(25, item.x, item.y);
      })


      this.scene.tweens.add({
        targets: specialPool,
        x: spriteCenter.x,
        y: spriteCenter.y,
        scale: 1,
        ease: "Power1",
        duration: (this.settings.destroySpeed / 1.5) / 2,
        onComplete: (event, sprites: BoardItem[]) => {
          sprites.forEach(sprite => {
            sprite.kill();
            this.scene.events.emit('update_score', { amount: 1 });
          });

          this.match3Controller.gameArray.forEach(row => {
            row.forEach(item => {
              const sprite = item.customData as BoardItem;
              sprite.rexShaker.shake();
            });
          });
          const backgroundScene = this.scene.scene.get('background') as Background;
          backgroundScene.rexShaker.shake();

          const powerup = this.settings.powerups.find(powerup => powerup.type === match.direction);
          if (powerup) {
            this.match3Controller.setValueAt(getCenterofMatch.row, getCenterofMatch.column, powerup.frame);
            spriteCenter.setData('isSpecial', powerup.type);
            spriteCenter.updateFrame(powerup.frame);
          }
        }
      });
    });
  }

  handleNormalMatch(matches: IMatch3Item[]) {
    // Get item ready for destroy //
    const items = matches.map((item: any) => item = this.match3Controller.customDataOf(item.row, item.column));
    this.bringToTop(this.particle);
    items.forEach(item => {
      this.bringToTop(item);
      this.emitter.explode(25, item.x, item.y);
    })

    const UIScene = this.scene.scene.get('userinterface-game') as UIGame;
    const UIscoreBackground = UIScene.score.getByName('background') as Phaser.GameObjects.Image;

    // Tween / Destroy Item //
    this.scene.tweens.add({
      targets: items,
      x: (this.bounds.x - this.bounds.left!) + UIscoreBackground.getBounds().centerX,
      y: (this.bounds.y - this.bounds.top!) + UIscoreBackground.getBounds().centerY,
      scale: 0.1,
      ease: "Back.In",
      duration: this.settings.destroySpeed,
      delay: this.scene.tweens.stagger(50, {}),
      onUpdate: (event, sprite: BoardItem) => {
        if (sprite.scaleX == 0.1) {
          sprite.setAlpha(0);
          this.scene.events.emit('update_score', { amount: 1 });
        }
      },
      onComplete: (event, sprites: BoardItem[]) => {
        sprites.forEach(sprite => {
          sprite.kill();
        });
      }
    });

    // Shake the score object //
    this.scene.time.delayedCall(this.settings.destroySpeed * 0.9, () => {
      const UIScene = this.scene.scene.get('userinterface-game') as UIGame;
      const UIscore = UIScene.score as Score;

      UIscore.rexShaker.setDuration((items.length * 50) + this.settings.destroySpeed * 0.1);
      UIscore.rexShaker.setMagnitude(Math.min(Math.max(items.length, 6), 12));
      UIscore.rexShaker.shake();
    });
  }

  powerupRandom(row: number, col: number, row2: number, col2: number) {
    const items = this.match3Controller.powerupRemoveRandom(8, row, col, row2, col2);
    this.handlePowerups(items);
  }

  powerupLine(row: number, col: number) {
    const powerup_sprite = this.match3Controller.customDataOf(row, col);
    const direction = powerup_sprite.getData('isSpecial');

    const items = direction == "horizontal" ? this.match3Controller.getRowItems(row) : this.match3Controller.getColumnItems(col);

    this.handlePowerups(items);
  }

  endOfTurn() {
    // Gameboard: Has new matches after replenishment, rehandle matches //
    if (this.match3Controller.matchInBoard()) {
      this.scene.events.emit('add_progression', { key: 'combos', value: 1 });
      this.scene.time.delayedCall(10, () => {
        this.handleMatches();
      });
    }

    // Gameboard: no new matched, reset turn //
    else {
      this.scene.events.emit('end_turn');
    }
  }

  nextTurn() {
    const hasPossibleMatch = this.match3Controller.matchPossible();
    if (!hasPossibleMatch) {
      this.powerupRandom(0, 1, 0, 3);
    } else {
      this.canPick = true;
    }
  }
}