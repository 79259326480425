import BoardItem from "../components/containers/board/boardItem";
import { IMatch3, IMatch3Item, IMatch3ItemFull, IMatch3ItemReplenished, IMatch3Powerups } from "../interfaces/board/IMatch3";

export default class Match3 {
  rows:number;
  columns:number;
  items:number;
  powerups: IMatch3Powerups;
  gameArray: IMatch3ItemFull[][];
  selectedItem: boolean | IMatch3Item;

  constructor(obj: IMatch3) {
    this.gameArray = [];
    this.selectedItem = false;
    this.rows = obj.rows;
    this.columns = obj.columns;
    this.items = obj.items;
    this.powerups = {
      random: {}
    }

    this.generateField();
  }

  // generates the game field
  generateField() {
    for (let i = 0; i < this.rows; i++) {
      this.gameArray[i] = [];
      for (let j = 0; j < this.columns; j++) {
        do {
          const randomValue = Math.floor(Math.random() * this.items);
          this.gameArray[i][j] = {
            value: randomValue,
            isEmpty: true,
            row: i,
            column: j
          }
        } while (this.isPartOfMatch(i, j));
      }
    }
  }

  // returns true if there is a match in the board
  matchInBoard() {
    for (let i = 0; i < this.rows; i++) {
      for (let j = 0; j < this.columns; j++) {
        if (this.isPartOfMatch(i, j)) {
          return true;
        }
      }
    }
    return false;
  }

  matchPossible(){    
    for (let i = 0; i < this.rows - 1; i++) {
      for (let j = 0; j < this.columns; j++) {
        if(!this.customDataOf(i, j)?.getData('isSpecial')){
          const GameArrayCloneHorizontal = this.gameArray.map(function(arr) {
            return arr.slice();
          });
          const tempObject = Object.assign(GameArrayCloneHorizontal[i][j]);
          GameArrayCloneHorizontal[i][j] = Object.assign(GameArrayCloneHorizontal[i + 1][j]);
          GameArrayCloneHorizontal[i + 1][j] = Object.assign(tempObject);
          if (this.isPartOfMatch(i, j, GameArrayCloneHorizontal)) {
            return true;
          }
        }else {
          return true;
        }
      }
    }

    for (let i = 0; i < this.rows; i++) {
      for (let j = 0; j < this.columns - 1; j++) {
        if(!this.customDataOf(i, j)?.getData('isSpecial')){
          const GameArrayCloneVertical = this.gameArray.map(function(arr) {
            return arr.slice();
          });
          const tempObject = Object.assign(GameArrayCloneVertical[i][j]);
          GameArrayCloneVertical[i][j] = Object.assign(GameArrayCloneVertical[i][j+1]);
          GameArrayCloneVertical[i][j+1] = Object.assign(tempObject);
          if (this.isPartOfMatch(i, j, GameArrayCloneVertical)) {
            return true;
          }        
        }else {
          return true;
        }
      }
    }

    return false;
  }

  // returns true if the item at (row, column) is part of a match
  isPartOfMatch(row: number, column: number, board?: IMatch3ItemFull[][]) {
    return this.isPartOfHorizontalMatch(row, column, board) || this.isPartOfVerticalMatch(row, column, board);
  }

  // returns true if the item at (row, column) is part of an horizontal match
  isPartOfHorizontalMatch(row: number, column: number, board?: IMatch3ItemFull[][]) {
    return this.valueAt(row, column, board) === this.valueAt(row, column - 1, board) && this.valueAt(row, column, board) === this.valueAt(row, column - 2, board) ||
      this.valueAt(row, column, board) === this.valueAt(row, column + 1, board) && this.valueAt(row, column, board) === this.valueAt(row, column + 2, board) ||
      this.valueAt(row, column, board) === this.valueAt(row, column - 1, board) && this.valueAt(row, column, board) === this.valueAt(row, column + 1, board);
  }

  // returns true if the item at (row, column) is part of an horizontal match
  isPartOfVerticalMatch(row: number, column: number, board?: IMatch3ItemFull[][]) {
    return this.valueAt(row, column, board) === this.valueAt(row - 1, column, board) && this.valueAt(row, column, board) === this.valueAt(row - 2, column, board) ||
      this.valueAt(row, column, board) === this.valueAt(row + 1, column, board) && this.valueAt(row, column, board) === this.valueAt(row + 2, column, board) ||
      this.valueAt(row, column, board) === this.valueAt(row - 1, column, board) && this.valueAt(row, column, board) === this.valueAt(row + 1, column, board)
  }

  // returns the value of the item at (row, column), or false if it's not a valid pick
  valueAt(row: number, column: number, board?: IMatch3ItemFull[][]) {
    if (!this.validPick(row, column, board)) {
      return false;
    }
    return board ? board[row][column].value : this.gameArray[row][column].value;
  }

  // Changes the value of item at (row, column), random if no value given //
  setValueAt(row: number, column: number, value: number) {
    if (!this.validPick(row, column)) {
      return false;
    }
    const randomValue = Math.floor(Math.random() * this.items);
    this.gameArray[row][column].value = value ? value : randomValue;
  }

  // returns true if the item at (row, column) is a valid pick
  validPick(row: number, column: number, board?: IMatch3ItemFull[][]) {
    const currentGameboard = board ? board : this.gameArray;
    return row >= 0 && row < this.rows && column >= 0 && column < this.columns && currentGameboard[row] != undefined && currentGameboard[row][column] != undefined;
  }

  // returns the number of board rows
  getRows() {
    return this.rows;
  }

  // returns the number of board columns
  getColumns() {
    return this.columns;
  }

  // returns array with items on 1 column //
  getColumnItems(col: number) {
    const columnItems = [];
    for (let i = 0; i < this.rows; i++) {
      columnItems.push({
        row: i,
        column: col
      })
    }

    return columnItems;
  }

  // returns array with items on 1 row //
  getRowItems(row: number) {
    const rowItems = [];
    for (let i = 0; i < this.columns; i++) {
      rowItems.push({
        row: row,
        column: i
      })
    }

    return rowItems;
  }

  // sets a custom data on the item at (row, column)
  setCustomData(row: number, column: number, customData: BoardItem) {
    this.gameArray[row][column].customData = customData;
  }

  // returns the custom data of the item at (row, column)
  customDataOf(row: number, column: number): BoardItem {
    return this.gameArray[row][column].customData;
  }

  // returns the selected item
  getSelectedItem() {
    return this.selectedItem;
  }

  // set the selected item as a {row, column} object
  setSelectedItem(row: number, column: number) {
    this.selectedItem = {
      row: row,
      column: column
    }
  }

  // deselects any item
  deselectItem() {
    this.selectedItem = false;
  }

  // checks if the item at (row, column) is the same as the item at (row2, column2)
  areTheSame(row: number, column: number, row2: number, column2: number) {
    return row == row2 && column == column2;
  }

  // returns true if two items at (row, column) and (row2, column2) are next to each other horizontally or vertically
  areNext(row: number, column: number, row2: number, column2: number) {
    return Math.abs(row - row2) + Math.abs(column - column2) == 1;
  }

  // swap the items at (row, column) and (row2, column2) and returns an object with movement information
  swapItems(row: number, column: number, row2: number, column2: number): IMatch3ItemReplenished[] {
    const tempObject = Object.assign(this.gameArray[row][column]);
    this.gameArray[row][column] = Object.assign(this.gameArray[row2][column2]);
    this.gameArray[row2][column2] = Object.assign(tempObject);
    return [{
      row: row,
      column: column,
      deltaRow: row - row2,
      deltaColumn: column - column2
    },
    {
      row: row2,
      column: column2,
      deltaRow: row2 - row,
      deltaColumn: column2 - column
    }]
  }

  // return the items part of a match in the board as an array of {row, column} object
  getMatchList(): IMatch3Item[]{
    const matches = [];
    for (let i = 0; i < this.rows; i++) {
      for (let j = 0; j < this.columns; j++) {
        if (this.isPartOfMatch(i, j)) {
          matches.push({
            row: i,
            column: j
          });
        }
      }
    }
    return matches;
  }

  // removes all items forming a match, from preset item list or from getMatchlist function
  removeMatches(items: IMatch3Item[]) {
    const matches = items ? items : this.getMatchList();
    matches.forEach(item => {
      this.setEmpty(item.row, item.column)
    })
  }

  // set the item at (row, column) as empty
  setEmpty(row: number, column: number) {
    this.gameArray[row][column].isEmpty = true;
  }

  // returns true if the item at (row, column) is empty
  isEmpty(row: number, column: number) {
    return this.gameArray[row][column].isEmpty;
  }

  // returns the amount of empty spaces below the item at (row, column)
  emptySpacesBelow(row: number, column: number) {
    let result = 0;
    if (row != this.getRows()) {
      for (let i = row + 1; i < this.getRows(); i++) {
        if (this.isEmpty(i, column)) {
          result++;
        }
      }
    }
    return result;
  }

  // arranges the board after a match, making items fall down. Returns an object with movement information
  arrangeBoardAfterMatch() {
    const result = []
    for (let i = this.getRows() - 2; i >= 0; i--) {
      for (let j = 0; j < this.getColumns(); j++) {
        const emptySpaces = this.emptySpacesBelow(i, j);
        if (!this.isEmpty(i, j) && emptySpaces > 0) {
          this.swapItems(i, j, i + emptySpaces, j)
          result.push({
            row: i + emptySpaces,
            column: j,
            deltaRow: emptySpaces,
            deltaColumn: 0
          });
        }
      }
    }
    return result;
  }

  // replenished the board and returns an object with movement information
  getBoardEmptySpaces(): IMatch3ItemReplenished[] {
    const result = [];
    for (let i = 0; i < this.getColumns(); i++) {
      if (this.isEmpty(0, i)) {
        const emptySpaces = this.emptySpacesBelow(0, i) + 1;
        for (let j = 0; j < emptySpaces; j++) {
          const randomValue = Math.floor(Math.random() * this.items);
          result.push({
            row: j,
            column: i,
            deltaRow: emptySpaces,
            deltaColumn: 0
          });
          this.gameArray[j][i].value = randomValue;
          this.gameArray[j][i].isEmpty = false;
        }
      }
    }
    return result;
  }

  powerupRemoveRandom(amount: number, row: number, col: number, row2: number, col2: number) {
    this.powerups.random.array = [{
      row: row,
      column: col
    }, {
      row: row2,
      column: col2
    }];

    this.getMultipleRandomItem(this.gameArray, amount, 0);

    const shuffledArray = Phaser.Utils.Array.Shuffle(this.powerups.random.array) as IMatch3Item[];
    return shuffledArray;
  }

  getMultipleRandomItem(array: IMatch3ItemFull[][], amount: number, current: number) {
    const random = {
      row: Math.floor(Math.random() * this.rows),
      column: Math.floor(Math.random() * this.columns)
    }
    const itemExists = this.powerups.random.array.find((item: IMatch3Item) => item.column == random.column && item.row == random.row);

    if (!itemExists) {
      this.powerups.random.array = [...this.powerups.random.array, random];
      current += 1
    }

    if (amount != current) {
      this.getMultipleRandomItem(array, amount, current)
    }
  }

}
