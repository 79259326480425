// @ts-ignore
import WebFont from "webfontloader";
import Phaser from "phaser";
import Constants from "../configs/constants";
import Scaling from "../configs/scaling";

export default class Boot extends Phaser.Scene {
    constructor() {
        super({
            key: "boot"
        });
    }

    preload() {
        // Load Fonts
        this.load.on("complete", () => {
            WebFont.load({
                custom: {
                    families: [Constants.FONT_BOLD,  Constants.FONT_REGULAR]
                },
                active: () => this.booted(true),
                inactive: () => this.booted(false)
            });
        });

        this.load.image("map_background", Scaling.imagePath("map_background", "png"));
        this.load.image("match_background", Scaling.imagePath("match_background", "png"));
        this.load.image("game_background", Scaling.imagePath("game_background", "png"));
        this.load.image("logo_company", Scaling.imagePath("logo_company", "png"));
        this.load.image("logo_campaign", Scaling.imagePath("logo_campaign", "png"));
    }

    booted(fontsLoaded: boolean) {
        // Scene: launch parallel background scene
        this.scene.launch('background');
        this.scene.stop();
    }
}
