import BaseMapMarker from "./_baseMarker";

export default class LockedMapMarker extends BaseMapMarker {
  constructor(scene: Phaser.Scene, x: number, y: number, type: string, mapID:number, level: number) {
    super(scene, x, y, type, mapID, level);
    this.scene.add.existing(this);

    const spriteContainer = this.createSprite('map_marker_closed');

    this.add([spriteContainer]);
  }
}