import BaseMapMarker from "./_baseMarker";

export default class UnlockedMapMarker extends BaseMapMarker {
  constructor(scene: Phaser.Scene, x: number, y: number, type: string, mapID: number, level: number) {
    super(scene, x, y, type, mapID, level);
    this.scene.add.existing(this);

    /** SPRITE */
    const spriteContainer = this.createSprite('map_marker_played');
    const sprite = spriteContainer.getByName('sprite') as Phaser.GameObjects.Sprite;
    sprite.setInteractive().on('pointerup', () => this.startLevel(level));

    /** STARS */
    const stars = this.createStarsObtained(sprite, level);

    this.add([spriteContainer, ...stars]);
  }
}