import Setup from "@/helpers/Setup";
import Phaser from "phaser";
import CircularProgress from 'phaser3-rex-plugins/plugins/circularprogress';
import Scaling from "../configs/scaling";
import Background from "./background";

export default class Load extends Phaser.Scene {
  constructor() {
    super({
      key: "load"
    });
  }

  preload() {
    /** CAPE */
    const gameSettings = Setup.data.settings.game;

    /** LOGO */
    const logoContainer = this.add.container(this.cameras.main.centerX, this.cameras.main.centerY);
    const logo = this.add.image(0, 0, 'logo_campaign').setOrigin(0.5, 0);

    /** LOADER */
    const circleloaded = new CircularProgress(this, {
      x: this.cameras.main.centerX,
      y: this.cameras.main.height - Scaling.getPixelbyDPR(80),
      radius: Scaling.getPixelbyDPR(30),
      thickness: 0.1,
      barColor: Phaser.Display.Color.HexStringToColor(Setup.getColor('primary')).color,
      trackColor: 0x000000,      
      valuechangeCallback: () => { }
    });
    this.add.existing(circleloaded);
    

    logoContainer.add([logo]);
    logoContainer.setY(logoContainer.y - (logoContainer.getBounds().height / 2))

    /** MAP: Load assets */
    this.load.image("map_border_left", Scaling.imagePath("map_border_left", "png"));
    this.load.image("map_border_right", Scaling.imagePath("map_border_right", "png"));
    this.load.image("map_marker_road", Scaling.imagePath("map_marker_road", "png"));

    this.load.atlas("map_markers", Scaling.imagePath("map_markers", "png"), Scaling.imagePath("map_markers_atlas", "json"));    

    this.load.image("map_marker_star_filled", Scaling.imagePath("map_marker_star_filled", "png"));
    this.load.image("map_marker_star_empty", Scaling.imagePath("map_marker_star_empty", "png"));
    this.load.image("button", Scaling.imagePath("button", "png"))
    
    /** BACKGROUND: Load assets */
    this.load.image("background_ui_score", Scaling.imagePath("background_ui_score", "png"));
    this.load.image("background_ui_stars", Scaling.imagePath("background_ui_stars", "png"));
    this.load.image("background_ui_turns", Scaling.imagePath("background_ui_turns", "png"));
    this.load.image("background_map_stars", Scaling.imagePath("background_map_stars", "png"));

    /** BUTTONS: Load assets */
    this.load.image("button_back", Scaling.imagePath("button_back", "png"));
    this.load.image("button_settings", Scaling.imagePath("button_settings", "png"));
    this.load.image("texture_popup_levelstart_button_alternate", Scaling.imagePath("button_outline", "png"))


    /** GAME: Load assets */
    this.load.image("game_board_top", Scaling.imagePath("game_board_top", "png"));
    this.load.image("game_board_bottom", Scaling.imagePath("game_board_bottom", "png"));
    this.load.image("game_board_background", Scaling.imagePath("game_board_background", "png"));
    this.load.image("game_board_item_background", Scaling.imagePath("game_board_item_background", "png"));
    this.load.spritesheet("game_board_particles", Scaling.imagePath("game_board_particles", "png"), { frameWidth: Scaling.getPixelbyDPR(56), frameHeight: Scaling.getPixelbyDPR(56)});
    this.load.spritesheet("game_board_items", Scaling.imagePath("game_board_items", "png"), { frameWidth: Scaling.getPixelbyDPR(60), frameHeight: Scaling.getPixelbyDPR(60) });
    
    
    /** ICONS: Load assets */
    this.load.image("icon_life_singular", Scaling.imagePath("icon_life_singular", "png"));
    this.load.image("icon_star_singular", Scaling.imagePath("icon_star_singular", "png"));

    /** POPUP: Load assets */
    this.load.image("popup_star_empty", Scaling.imagePath("popup_star_empty", "png"));
    this.load.image("popup_star_filled", Scaling.imagePath("popup_star_filled", "png"));

    /** AUDIO: Load sound assets */
    this.load.audio("theme", ["sounds/games/match3/background.ogg", "sounds/games/match3/background.mp3"]);
    this.load.audio("button_click", ["sounds/games/match3/button-click.mp3"]);
    this.load.audio("audio_confirm", ["sounds/games/match3/button_confirm.ogg", "sounds/games/match3/button_confirm.mp3"]);
    this.load.audio("audio_match", ["sounds/games/match3/match.ogg", "sounds/games/match3/match.mp3"]);
    this.load.audio("audio_pop", ["sounds/games/match3/pop.ogg", "sounds/games/match3/pop.mp3"]);
    this.load.audio("audio_select", ["sounds/games/match3/select.ogg", "sounds/games/match3/select.mp3"]);
    this.load.audio("audio_win", ["sounds/games/match3/win.ogg", "sounds/games/match3/win.mp3"]);

    /** PRELOAD: progression */
    this.load.on("progress", (value: number) => {
      circleloaded.setValue(value)
    });

    /** PRELOAD: complete */
    this.load.on("complete", () => {
      this.time.addEvent({
        delay: 1000,
        callback: () => {
          
          const audio_button = this.sound.add('button_click', { volume: 0.1 });
          audio_button.play();

          const backgroundScene = this.scene.get("background") as Background;
          if (backgroundScene) {
            backgroundScene.playMusic();
          }
          this.game.events.emit("loaded");

        }
      });
    });
  }
}


