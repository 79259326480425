import Phaser from "phaser";
import Scaling from "../configs/scaling";
import MapLevelPath from "../components/containers/map/levelPath";
import { IMapData } from "../interfaces/IMapData";

// @ts-ignore
import { ScrollingCamera } from "../controllers/scrollingCamera";
import globalSaveFile from "@/utils/game/GlobalSaveFile";
import Setup from "@/helpers/Setup";
import { ILevel } from "../interfaces/level/ILevel";
import BaseMapMarker from "../components/containers/map/markers/_baseMarker";
import { millisToMinutesAndSeconds } from "../configs/helpers";
import Background from "./background";

export default class Map extends Phaser.Scene {
  navigateToLevel!: number;
  borderLeft!: Phaser.GameObjects.TileSprite;
  borderRight!: Phaser.GameObjects.TileSprite;
  cameraMap!: any;

  mapID = 1;

  constructor() {
    super({ key: "map" });
  }

  init(mapParams: IMapData) {    
    this.navigateToLevel = mapParams.navigateToLevel;

    /** LEVEL: Retrieve level data */
    const levelsDataRaw = JSON.parse(Setup.getValue('settings.game.levelsJson').value) || {};
    const levelsData = levelsDataRaw.levels as ILevel[];
    globalSaveFile.addMap(this.mapID, levelsData.length);
  }

  preload() {
    /** SCENES: launch parallel user interface */
    this.scene.launch('userinterface-map', { stars: globalSaveFile.getMap(this.mapID)?.totalStars || 0, score: globalSaveFile.getMap(this.mapID)?.totalScore || 0});
  }

  create() {
    /** BACKGROUND: Change background if Cape has double background setting active */
    if(Setup.getValue("settings.game.hasTwoBackground", false)) {
      const backgroundScene = this.scene.get('background') as Background;
      backgroundScene.setBackground('map_background');
    }

    /** AUDIO */
    const audioClick = this.sound.add("audio_select", { volume: 0.5 });
    const audioSelect = this.sound.add("audio_confirm", { volume: 0.5 });
    const audioPop = this.sound.add("audio_pop", { volume: 0.5 });

    /** MAP: Path */
    const mapLevelPath = new MapLevelPath(this, 0, 0, this.mapID);

    /** MAP: Borders */
    this.borderLeft = this.add.tileSprite(0, 0, Scaling.getPixelbyDPR(124), this.cameras.main.height, "map_border_left").setOrigin(0, 0);
    this.borderRight = this.add.tileSprite(this.cameras.main.width, 0, Scaling.getPixelbyDPR(124), this.cameras.main.height, "map_border_right").setOrigin(1, 0);
    mapLevelPath.setPosition(0, this.borderLeft.displayHeight / 4);

    /** CAMERAS: Config */
    this.cameraMap = new ScrollingCamera(this, {
      width: this.cameras.main.width,
      height: this.cameras.main.height,
      bottom: mapLevelPath.getBounds().bottom + Scaling.getPixelbyDPR(70)
    });
    this.cameraMap.ignore([this.borderLeft, this.borderRight]);
    this.cameras.main.ignore([mapLevelPath]);

    /** CAMERAS: Scroll to level */
    const scrollToLevel = mapLevelPath.getFirst("level", this.navigateToLevel || globalSaveFile.getLatestLevelInMap(this.mapID)) as BaseMapMarker;
    this.cameraMap.scrollY = scrollToLevel.getBounds().centerY - (this.cameras.main.height / 2);
    
    
    /** EVENTS */
    this.events.on('start_level', (data: any) => {
      /** SAVEDATA: retrieve user data of level */
      const userLevelData = globalSaveFile.getLevel(this.mapID, data.level);
      

      /** POPUP: Open popup with relevant user data */
      this.scene.launch('popup-levelstart', {
        level: data.level,
        stars: userLevelData?.stars,
        highscore: userLevelData?.totalScore,
        playtime: millisToMinutesAndSeconds(userLevelData?.playTime || 0),
        onCancel: () => {
          const popupScene = this.scene.get('popup-levelstart');
          popupScene.scene.stop();
        },
        onStart: () => {
          this.shutdown();
          this.scene.start('game', { level: data.level });
        }
      });
    });

    this.events.on('start_level_latest', () => {
      const latestLevel = globalSaveFile.getNextLevelInMap(this.mapID);
      const userLevelData = globalSaveFile.getLevel(this.mapID, latestLevel);

      /** POPUP: Open popup with relevant user data */
      this.scene.launch('popup-levelstart', {
        level: latestLevel,
        stars: userLevelData?.stars,
        highscore: userLevelData?.totalScore,
        playtime: 0,
        onCancel: () => {
          const popupScene = this.scene.get('popup-levelstart')
          popupScene.scene.stop()
        },
        onStart: () => {
          this.shutdown()
          this.scene.start('game', { level: latestLevel })
        },
      })
    })
  }

  update() {
    this.borderLeft.tilePositionY = (this.cameraMap.scrollY * 1);
    this.borderRight.tilePositionY = (this.cameraMap.scrollY * 1) - (this.borderLeft.displayHeight / 2);
  }

  shutdown() {
    /** EVENTS: cleanup */
    this.events.off('start_level');

    /** SCENES: cleanup */
    const UIScene = this.scene.get('userinterface-map');
    UIScene.scene.stop();

    const popupScene = this.scene.get('popup-levelstart');
    popupScene.scene.stop();

    this.scene.stop();
  }
}
