import { IInputController } from "../interfaces/IInputController";

export default class InputController {
  options: IInputController

  constructor(options: IInputController) {
    this.options = options;
    let downX = 0;
    let downY = 0;
    let upX = 0;
    let upY = 0;
    const threshold = this.options.threshold ? this.options.threshold : 50;

    this.options.target.on("pointerdown", (pointer: Phaser.Input.Pointer) => {
      downX = pointer.x;
      downY = pointer.y;
      this.options.callbackDown ? this.options.callbackDown({ event: pointer }) : null;
    });

    this.options.target.on("pointerup", (pointer: Phaser.Input.Pointer) => {
      upX = pointer.x;
      upY = pointer.y;
      if (upX < downX - threshold) {
        this.options.callbackUp({
          event: pointer,
          direction: "left"
        });
      } else if (upX > downX + threshold) {
        this.options.callbackUp({
          event: pointer,
          direction: "right"
        });
      } else if (upY < downY - threshold) {
        this.options.callbackUp({
          event: pointer,
          direction: "up"
        });
      } else if (upY > downY + threshold) {
        this.options.callbackUp({
          event: pointer,
          direction: "down"
        });
      } else {
        this.options.callbackUp({
          event: pointer,
          direction: undefined
        });
      }
    });
  }
}
